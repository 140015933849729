/* eslint-disable */
import React, { Component } from 'react';
import { URLAPI } from '../../utility/ApiMethods';
import LocationDetails from '../layouts/LocationDetails';
import DashboardRequestCard from '../layouts/DashboardRequestCard';
import LoadingSpinner from '../../resources/loadingSpinner';
import moment from 'moment';
import { formatDateforPicker } from '../../utility/dateTime';
import SearchInput from '../inputs/SearchInput';

import Greeting from '../layouts/Greeting';
import TransactionCard from '../layouts/TransactionCard';
import { FaMailBulk, FaSave, FaUserTie } from 'react-icons/fa';
import { FcApproval } from 'react-icons/fc';
import TablePaginationActions from '../common/TablePaginationActions';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import MemoPDF from '../inputs/MemoPDF';
import { Get } from '../../utility/fetch';

class Dashboard extends Component {
  constructor(history) {
    super(history);
    this.state = {
      isLoading: false,
      cardValues: {},
      details: [],
      errorMsg: '',
      startDate: '',
      endDate: '',
      searchText: '',
      selectedDate: 'All Cases',
      calendarDate: new Date(),
      transactionHistory: [],
      totalTransact: [],
      transaction: [],
      pendingRequests: [],
      declinedRequests: [],
      approvedRequests: [],
      savedRequests: [],
      requestcount: '',
      requestCategory: '',
      initiatedCategory: '',
      intiatedCount: '',
      approved: '',
      declined: '',
      pendingCategory: '',
      pendingCount: '',
      totalSavedCategory: '',
      totalSavedCount: '',
      searchText: null,
      totalDeclinedCount: 0,
      // pagination state value
      rowsPerPage: 10,
      page: 0,
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ ...this.state, page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ ...this.state, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDate = async (e) => {
    const { startDate, endDate, selectedDate, searchText } = this.state;
    const { value } = e.target;
    selectedDate(value);
    searchText('');

    switch (value) {
      case 'Today':
        // get meetings for today
        startDate(formatDateforPicker(new Date()));
        endDate(formatDateforPicker(new Date()));
        break;
      case 'This Week':
        // get meetings for this week
        startDate(formatDateforPicker(moment().startOf('week').toDate()));
        endDate(formatDateforPicker(moment().endOf('week').toDate()));
        break;
      case 'This Month':
        // get meetings for this month
        startDate(formatDateforPicker(moment().startOf('month').toDate()));
        endDate(formatDateforPicker(moment().endOf('month').toDate()));
        break;
      case 'Calendar':
        // get meetings for the day selected in the calendar
        startDate(formatDateforPicker(this.state.calendarDate));
        endDate(formatDateforPicker(this.state.calendarDate));
        break;
      case 'All Cases':
        // get meetings for the day selected in the calendar
        startDate('');
        endDate('');
        break;
      default:
        break;
    }
  };

  getCardValues = (data) => {
    console.log('login data', data);

    try {
      this.setState({
        loading: false,
        requestCategory: data[0].category,
        requestCount: data[0].no,
        initiatedCategory: data[1].category,
        intiatedCount: data[1].no,
        pendingCategory: data[2].category,
        pendingCount: data[2].no,
        totalSavedCategory: data[3].category,
        totalSavedCount: data[3].no,
        // totalDeclinedCategory: data[4].category,
        // totalDeclinedCount: data[4].no,
      });
    } catch (err) {
      console.log(err);
    }
  };

  getTransaction = (data) => {
    console.log(data)
    let _transact = Array.isArray(data) ? [...data] : [];
    this.setState({ transaction: _transact });
    this.setState({ totalTransact: _transact });

    this.setState({requestCount: _transact.length})
    let _saved = Array.isArray(data) ? data.filter((item) => item.name === 'Saved') : [];
    this.setState({ savedRequests: _saved });
    this.setState({totalSavedCount: _saved.length})

    let _pending = Array.isArray(data) ? data.filter((item) => item.name === 'Pending') : [];
    this.setState({ pendingRequests: _pending });
    this.setState({pendingCount: _pending.length});

    let _approved = Array.isArray(data) ? data.filter((item) => item.name === 'Approved') : [];
    this.setState({ approvedRequests: _approved });
    this.setState({intiatedCount: _approved.length});

    let _declined = Array.isArray(data) ? data.filter((item) => item.name === 'Declined') : [];
    this.setState({ declinedRequests: _declined });
    this.setState({totalDeclinedCount: _declined.length});
  };

  switchTables = (id) => {
    if (id === 'pending') {
      this.setState({ transaction: this.state.pendingRequests });
    } else if (id === 'approved') {
      this.setState({ transaction: this.state.approvedRequests });
    } else if (id === 'saved') {
      this.setState({ transaction: this.state.savedRequests });
    } else if (id === 'total') {
      this.setState({ transaction: this.state.totalTransact });
    } else if (id === 'declined') {
      this.setState({ transaction: this.state.declinedRequests });
    }
  };

  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword });
  };

  componentDidMount() {
    Get(URLAPI.dashboard, this.getTransaction);
    // Get(URLAPI.requestCount, this.getCardValues);
  }

  render() {
    const {
      isLoading,
      errorMsg,
      requestCategory,
      requestCount,
      initiatedCategory,
      intiatedCount,
      pendingCategory,
      pendingCount,
      declinedCategory,
      declinedCount,
      totalDeclinedCount,
      totalSavedCategory,
      totalSavedCount,
      transaction,
      page,
      rowsPerPage,
    } = this.state;

    const tableData =
      rowsPerPage > 0
        ? transaction.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : transaction;

    return (
      <>
        <div className='w-100'>
          {isLoading && <LoadingSpinner />}
          <div className='w-100 flex flex-v-center space-between'>
            <Greeting />
            {/* <LocationDetails /> */}
          </div>

          <div className='flex wrap row space-between'>
            <div
              className='col-2 no-margin card-cursor'
              onClick={() => {
                this.switchTables('total');
              }}>
              <DashboardRequestCard
                header={requestCategory || "Total Request"}
                header2={requestCount || '0'}
                // title='My Request Statistics'
                icon={<FaUserTie fontSize='30px' />}
                // cardDetail={[]}
              />
            </div>
            <div
              className='col-2 no-margin card-cursor'
              onClick={() => {
                this.switchTables('approved');
              }}>
              <DashboardRequestCard
                header={initiatedCategory || "Total Approved"}
                header2={intiatedCount || '0'}
                // title='My Request Statistics'
                icon={<FcApproval fontSize='30px' />}
                // cardDetail={[]}
              />
            </div>
            <div
              className='col-2 no-margin card-cursor'
              onClick={() => {
                this.switchTables('pending');
              }}>
              <DashboardRequestCard
                header={pendingCategory || "Total Pending"}
                header2={pendingCount || '0'}
                // title='My Request Statistics'
                icon={<FaMailBulk fontSize='30px' />}
                // cardDetail={[]}
              />
            </div>
            <div
              className='col-2 no-margin card-cursor'
              onClick={() => {
                this.switchTables('declined');
              }}>
              <DashboardRequestCard
                header="Total Declined"
                header2={totalDeclinedCount || '0'}
                // title='My Request Statistics'
                icon={<FaSave fontSize='30px' />}
              />
            </div>
            <div
              className='col-2 no-margin card-cursor'
              onClick={() => {
                this.switchTables('saved');
              }}>
              <DashboardRequestCard
                header={totalSavedCategory || "Total Saved"}
                header2={totalSavedCount || '0'}
                // title='My Request Statistics'
                icon={<FaSave fontSize='30px' />}
              />
            </div>
          </div>

          <br />
          <div className='w-100 flex justify-content-end m-t-20'>
            <SearchInput onChange={(e) => this.searchSpace(e)} />
          </div>

          <div className='w-100 m-t-20'>
            <div
              className='w-100 flex space-between m-t-20'
              style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
              <p>Transaction History</p>
            </div>

            {tableData
              .filter((data) => {
                if (this.state.search == null) return data;
                else if (
                  data.title.toLowerCase().includes(this.state.search.toLowerCase())
                ) {
                  return data;
                }
              })
              .map((item) => (
                <TransactionCard
                  key={item.id}
                  {...item}
                  id={item.id}
                  beneficiary={item.beneficiary}
                  category={item.category}
                  firstName={item.firstName}
                  cost={item.cost}
                  details={item.details}
                  lastName={item.lastName}
                  name={item.name}
                  title={item.title}
                  date={item.date}
                  requestDate={item.requestDate}
                  mda={item.mda}
                  requestor={item.memoInitiator}
                  documents={item.documents}
                  approverDocuments={item.approverDocuments}
                  signature64={item.signature64}
                />
              ))}

            {isLoading && <div className='w-100 center-text p-20'>Loading...</div>}
            {errorMsg && <div className='w-100 center-text p-20'>{errorMsg}</div>}

            {transaction?.length > 10 && (
              <TablePaginationActions
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                count={transaction.length}
                changeRowsPerPage={this.handleChange}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
