/*eslint-disable */
import { Component } from 'react';
import * as React from 'react';
import moment from 'moment';
import ColorController from '../../controllers/ColorController';
import TrainingRequestDetails from '../layouts/TrainingRequestDetails';

// Icons
import { BiEditAlt } from 'react-icons/bi';
import { GiTrashCan } from 'react-icons/gi';
import ReactTooltip from 'react-tooltip';

const alignLeft = {
  textAlign: 'left',
};

class TransactionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLeaveRequestDetail: false,
    };
  }

  getColor = (name) => {
    if (name === 'Approved') return 'green';
    else if (name === 'Pending') return '#FDCC29';
    else if (name === 'Declined') return 'red';
    else return 'grey';
  };

  statusText = (name) => {
    if (name === 'Approved') return 'Approved';
    else if (name === 'Pending') return 'Currently with';
    else if (name === 'Declined') return 'Declined';
    else return 'Saved';
  };

  closeModal = () => {
    this.setState({
      showLeaveRequestDetail: false,
    });
  };

  render() {
    return (
      <>
        <div className=''>
          <div
            className='flex space-between m-t-15 w-100'
            data-tip='Click to view details'
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              padding: this.props.padding || '.6rem',
              fontSize: this.props.fontSize || '13px',
              cursor: 'pointer',
              backgroundColor: this.state.showLeaveRequestDetail
                ? '#fff'
                : this.props.bgColor || '#fff',
              transition: 'all 0.6s ease-in',
            }}
            onClick={() => this.setState({ showLeaveRequestDetail: true })}>
            {/* <div
            style={{ padding: '.4rem', fontSize: '13px' }}
            className={`${this.props.title && this.props.title ? 'col-10' : 'col-6'
              } my-auto`}>
            <h4>Title:- {this.props.title ||
              ''}</h4>
          </div> */}
            {/* <div
            style={{ padding: '.4rem', fontSize: '13px' }}
            className={`${this.props.category && this.props.category === this.props.title ? 'col-10' : 'col-6'
              } my-auto`}>
            <h4 className="bolder-text">Category:- {this.props.category ||
              ''}</h4>
          </div> */}
            <div
              style={{ padding: '.4rem', fontSize: '13px' }}
              className={`${
                this.props.beneficiary && this.props.beneficiary === this.props.beneficiary
                  ? 'col-6'
                  : 'col-6'
              } my-auto`}>
              <h4 >{this.props.title || ''}</h4>
            </div>

            {/* <div
            style={{ padding: '.4rem', fontSize: '13px' }}
            className={`${this.props.category && this.props.category === this.props.category
              } my-auto`}>
            <h4 className="bolder-text">{this.props.category ||
              ''}</h4>
          </div> */}
            <div
              className='flex space-around text-center col-6 my-auto'
              style={{ padding: '.4rem' }}>
              <div className='m-r-auto flex'>
                <div
                  className='m-r-5'
                  style={{
                    width: '1.1rem',
                    backgroundColor: `${this.getColor(this.props.name)}`,
                    borderRadius: '50%',
                    height: '1.1rem',
                  }}></div>
                <div className='my-auto flex '>
                  <p>
                    {' '}
                    {this.statusText(this.props.name)}{' '}
                    {this.props.firstName + ' ' + this.props.lastName || 'Currently with'}
                  </p>
                </div>
              </div>
            </div>

            {/* <div
            className='flex space-around text-center col-4 my-auto'
            style={{ padding: '.4rem' }}>
            <div className='m-r-auto flex'>
              <div className='m-r-5 m-l-10'>
                {this.props.name || 'Currently with Anthony Okungbowa (HOS)'}
              </div>

              <div
                className=' my-auto m-l-10'
                style={{
                  width: '1.1rem',

                  borderRadius: '50%',
                  height: '1.1rem',
                }}></div>

            </div>
          </div> */}

            <div
              className='col-2 my-auto pd-l-auto'
              style={{
                borderLeft: '1px solid gray',
                padding: '.4rem',
                // paddingLeft: ".56rem",
              }}>
              <b className='bold-text'>Last Treated:</b>
              {moment(this.props.lastTreated).format('DD.MM.YYYY') || '08.08.2020'}
            </div>
          </div>
          <ReactTooltip />
          {this.state.showLeaveRequestDetail ? (
            <div>
              <TrainingRequestDetails
                // height={height}
                id={this.props.id}
                beneficiary={this.props.beneficiary}
                category={this.props.category}
                firstName={this.props.firstName}
                lastName={this.props.lastName}
                name={this.props.name}
                title={this.props.title}
                cost={this.props.cost}
                details={this.props.details}
                date={this.props.date}
                requestDate={this.props.requestDate}
                closeModal={this.closeModal}
                style={{ transition: 'all 0.6s ease-in' }}
                mda={this.props.mda}
                requestor={this.props.requestor}
                document={this.props.documents}
                approverDocuments={this.props.approverDocuments}
                signature64={this.props.signature64}

                // cardDetails={this.props.approvalRequest && this.props.approvalRequest}
                // approvalRequests={this.props.approvalRequests && this.props.approvalRequests}
                // setApprovalRequests={
                //   this.props.setApprovalRequests && this.props.setApprovalRequests
                // }
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
}

export default TransactionCard;
