/* eslint-disable */
import React from 'react';
import { Switch, Route } from 'react-router';
/* eslint-disable */
import { pure } from 'recompose';

import ProtectedRoute from './ProtectedRoute';
import SessionHoc from '../components/hoc/SessionHOC';
import Dashboard from '../components/pages/Dashboard';
import logout from '../utility/logout';
import Home from '../components/pages/Home';

// Request Management App
const ManageRequest = React.lazy(() => import('../components/pages/ManageRequest'));
const InitiateRequest = React.lazy(() => import('../components/pages/InitiateRequest'));
const Report = React.lazy(() => import('../components/pages/Report'));
const Settings = React.lazy(() => import('../components/pages/Settings'));
const History = React.lazy(() => import('../components/pages/History'));

export default pure(() => (
  <Switch>
    <ProtectedRoute path='/admin-portal/' exact component={SessionHoc(Dashboard)} />
    <ProtectedRoute path='/admin-portal/home' exact component={Home} />
    <ProtectedRoute
      path='/admin-portal/request/manage-request'
      exact
      component={SessionHoc(ManageRequest)}
    />
    <ProtectedRoute
      path='/admin-portal/request/initiate-request'
      exact
      component={SessionHoc(InitiateRequest)}
    />

    <ProtectedRoute path='/admin-portal/request/history' exact component={SessionHoc(History)} />
    <ProtectedRoute path='/admin-portal/request/report' exact component={SessionHoc(Report)} />
    <ProtectedRoute path='/admin-portal/request/settings' exact component={SessionHoc(Settings)} />
    <ProtectedRoute path='/admin-portal/request/logout' exact component={logout} />
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
