import React from 'react';
import Cookies from 'js-cookie';
// Icons
import { RiLogoutCircleLine /* , RiSettings3Line */ } from 'react-icons/ri';
import { RiBarChartFill } from 'react-icons/ri';
// import { FaRegCheckCircle } from 'react-icons/fa';
// import { ImCancelCircle } from 'react-icons/im';
import { IoIosSettings } from 'react-icons/io';
import { BsPerson } from 'react-icons/bs';
import { VscPreview } from 'react-icons/vsc';
// import { ImFileText } from 'react-icons/im';
import { HiOutlineChatAlt2 } from 'react-icons/hi';
import { AiOutlineHistory } from 'react-icons/ai';
//import Cookies from 'js-cookie';
const sidebarMenu = () => {
  // const fullName = Cookies.get('fullname');

  // console.log('Full Name: ', fullName);

  const menu = [];
  const menu1 = [];

  // menu.push({
  //   title: 'Home',
  //   href: '/admin-portal/home',
  //   icon: <AiOutlineHome className='icon' />,
  // });

  menu.push({
    title: 'Dashboard',
    href: '/admin-portal',
    icon: <RiBarChartFill className='icon' />,
  });
  menu.push({
    title: 'Initiate Request',
    icon: <HiOutlineChatAlt2 className='icon' />,
    href: '/admin-portal/request/initiate-request',

  });

  menu.push({
    title: 'Review Request',
    icon: <VscPreview className='icon' />,
    href: '/admin-portal/request/manage-request',

  });
  menu.push({
    title: 'History',
    icon: <AiOutlineHistory className='icon' />,
    href: '/admin-portal/request/history',
  });

  menu.push({
    title: 'Logout',
    icon: <RiLogoutCircleLine className='icon' />,
    href: '/admin-portal/request/logout',
  });
  

  menu1.push({
    title: 'Dashboard',
    href: '/admin-portal',
    icon: <RiBarChartFill className='icon' />,
  });
  menu1.push({
    title: 'Initiate Request',
    icon: <HiOutlineChatAlt2 className='icon' />,
    href: '/admin-portal/request/initiate-request',

  });

  menu1.push({
    title: 'Review Request',
    icon: <VscPreview className='icon' />,
    href: '/admin-portal/request/manage-request',

  });
  menu1.push({
    title: 'History',
    icon: <AiOutlineHistory className='icon' />,
    href: '/admin-portal/request/history',
  });

  menu1.push({
    title: 'Report',
    icon: <BsPerson className='icon' />,
    href: '/admin-portal/request/report',
  });

  menu1.push({
    title: 'Settings',
    icon: <IoIosSettings className='icon' />,
    href: '/admin-portal/request/settings',
  });
  menu1.push({
    title: 'Logout',
    icon: <RiLogoutCircleLine className='icon' />,
    href: '/admin-portal/request/logout',
  });




if(Cookies.get('isSettings') == 'true')
  return menu1;
else
  return menu;


  
};

export default sidebarMenu;
