import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import Select from 'react-select';

const { Option } = Select;
function SearchDocument(prop) {
  const [title, setTitle] = useState('');
  
  const [maVal, setMapVal] = useState([]);
  let [count, setCount] = useState(0);
 
  const [options, setOptions] = useState([]);

  console.log(maVal);

  let userId = Cookies.get('userId');

  const handleFile = (doc) => {
 
 let k =   prop?.handleDocAdd({
      key: count++,
      docTitle: doc.label,
      docPath: doc.value
    });


    setCount(count);
  };
 
  useEffect(() => {
    console.log(userId);
    console.log(title);
    searchDocs(userId, title);

  }, [title]);

  const searchDocs = () => {

    const options = {
      url: `https://edogoverp.com/services/api/dashboard/searchdocument?user_id=${userId}&DocTitle=${title}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        crossorigin: true,
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Origin': '*',
      },
    };
    // console.log(options);
    return axios(options)
      .then((res) => {
        console.log(res);
        Array.isArray(res.data) && setOptions(res.data?.map((item) => ({
          value: item.fileurl, label: item.title
        })));

        setMapVal(res.data.isArray && res?.data?.map((tem) =>
          <Option
            value={tem.title}
            key={tem.fileurl}
          >
            {tem.title}
          </Option>

        ));

        return { options };
      


      });

  };

  return (
    <div style={{ width: '100%' }}>
      <Select
        name="form-field-name"
        value={title}
        placeholder = "Select Mfiles file upload..."
        options={options}
        onChange={(e) => handleFile(e)}
        onInputChange={e => setTitle(e)}
      />
    </div>
  );
}

export default SearchDocument;



