/* eslint-disable */

import React, { Component } from 'react';
import {
  deleteMessage,
  errorMessage,
  successMessage,
  confirmToSubmit,
} from '../../resources/alert';
import { IoMdClose } from 'react-icons/io';
import { BsUpload } from 'react-icons/bs';
import LoadingSpinner from '../../resources/loadingSpinner';
import parser from 'html-react-parser';
import SearchDocument from '../SearchDocument';
import UploadButton from '../UploadButton';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import { BsFillCaretUpFill } from 'react-icons/bs';
import TrainingRequestDetails from './TrainingRequestDetails';
import { URLAPI } from '../../utility/ApiMethods';
import { Get, Put } from '../../utility/fetch';
import TextareaInput from '../inputs/TextareaInput';

import Cookies from 'js-cookie';

class ManageRequestFormModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      requestId: 0,
      title: '',
      details: '',
      costImplication: 0,
      durationDate: '',
      mFilesArray: [],
      comment: '',
      action: '',
      status: '',
      loading: false,
      approveButton: 'Approve',
      DeclineButton: 'Decline',
      req: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  recImagg = (value) => {
    console.log(value);

    this.setState({ mFilesArray: [...this.state.mFilesArray, value] });

    // imgis.image = e;
    // imgis.name = n;
    // facImg.push(imgis);

    // setImagess(facImg)
    //setImgState()
  };

  handleDocAdd = (value) => {
    console.log(value);
    this.setState({ mFilesArray: [...this.state.mFilesArray, value] });
  };
  del = (id) => {
    console.log(id);
    console.log(this.state.mFilesArray);
    // dataSource.filter((item) => item.key !== key)
    let pp = this.state.mFilesArray.filter((x) => x.key !== id);
    this.setState({ mFilesArray: pp });
    // imageHolder.name="";
    // imageHolder.fileUrl="";
    // handleImage("","")
  };

  validateForm = () => {
    const { comment } = this.state;
    if (!comment) return 'Please, enter a response';

    return null;
  };

  submitForm = (e) => {
    e.preventDefault();
    const error_data = this.validateForm();

    if (error_data) {
      errorMessage(error_data);
      // this.props.closeModal();
    } else if (error_data !== this.validateForm) {
      let request = {
        Id: parseInt(this.state.requestId),
        Comment: this.state.comment,
        Action: 'Approve',
        documents: this.state.mFilesArray,
        //status: 0,
      };

      if (this.state.approveButton === 'Approve') {
        this.approveMemo(request);
      }
    }
  };
  submitForm2 = (e) => {
    e.preventDefault();
    const error_data = this.validateForm();

    if (error_data) {
      errorMessage(error_data);
      // this.props.closeModal();
    } else if (error_data !== this.validateForm) {
      let request = {
        id: parseInt(this.state.requestId),
        comment: this.state.comment,
        action: 'Decline',
        documents: this.state.mFilesArray,
        //status: 0,
      };

      if (this.state.DeclineButton === 'Decline') {
        this.approveMemo(request);
      }
    }
  };

  approveMemo = (memo) => {
    console.log('logging', memo);

    if (this.state.requestId) {
      this.setState({ loading: true }, () => {
        Put(
          memo,
          URLAPI.requestApprovals.Put + '/' + this.state.requestId,
          this.onSubmitAfterApproval,
        );
      });
    }
  };

  onSubmitAfterApproval = (data) => {
    // console.log('entered submit after approval function')

    if (data.code === 1) {
      this.setState({ req: this.props.filterReviewsOnApprover, loading: false });
      successMessage(data?.message);
      Get(URLAPI.requestApprovals.Get, this.props.getApproval);
      Get(URLAPI.requestApprovals.Get2, this.props.getTreated);
      this.props.closeModal();
    } else if (data.code === 0) {
      this.props.closeModal();
      errorMessage(data.message);
      this.setState({
        ...this.state,
        loading: false,
      });
    } else {
      errorMessage(data.message);
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      requestId: this.props.requestId,
      title: this.props.title,
      category: this.props.category,
      details: this.props.details,
      durationDate: this.props.date,
      costImplication: this.props.cost,
      status: this.props.status,
      approvalStatus: this.props.approvalStatus
    });
    Get(URLAPI.requestApprovals.Get, this.props.getApproval);
  }

  closeModal = () => {
    this.setState({ visible: false });
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <div className='overlay'>
          <div className='flex flex-v-center flex-h-center flex-direction-column'>
            
            <div className='modal-box'  style={{
                  boxShadow: '0px 2px 6px #0000000A',
                  position: 'relative',
                  width: "50%",
                }}>
              <div
                className='w-100 flex space-between m-t-20'
                style={{ padding: '15px', backgroundColor: '#d5ffd5' }}>
                <p>Requests</p>
                <p>Requesting: {this?.props?.initiator}</p>
              </div>
              <IoMdClose
                    className='pointer m-l-5'
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '100%',
                      fontSize: '24px',
                      color: '#fff',
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#F74F11',
                      padding: '3px',
                    }}
                    onClick={this.props.closeModal}
                  />
              <div className='content'>
                <div className='w-100 bg-white'>
                  <div className='flex flex-direction-column'>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='col-2-4 no-padding no-margin bold-text'>Title: </div>
                      <div className='w-80 '>{this.state.title}</div>
                    </div>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='col-2-4 no-padding no-margin bold-text'>Category: </div>
                      <div className='w-80 '>{this.state.category}</div>
                    </div>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='col-2-4 no-padding no-margin bold-text'>Initiation Date: </div>
                      <div className='w-80 '>{this.state.durationDate}</div>
                    </div>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='col-2-4 no-padding no-margin bold-text'>Request Details: </div>
                      <div className='w-80 '>{parser(this.state.details?.replaceAll("<td>", "<td className='border-table'>"))}</div>
                    </div>
                    <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                      <div className='col-2-4 no-padding no-margin bold-text'>Cost Implication: </div>
                      <div className='w-80 '>₦{this.state.costImplication?.toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12 bg-white'>


                    {this.state.status === null && <div>
                      <TextareaInput
                        onChange={(e) => this.handleChange(e)}
                        className='w-100 m-b-10'
                        name='comment'
                        label='Response'
                        required
                        value={this.state.comment}
                      />

                      <div style={{ marginBottom: '10px', minWidth: '150px' }}>
                        <UploadButton width='50%' sendImagg={this.recImagg} />
                      </div>
                      <div style={{ width: '50%' }}>
                        <SearchDocument handleDocAdd={this.handleDocAdd} />

                      </div>
                    </div>
                    }


                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginBottom: '8px' }}>
                      <div style={{ width: '100%' }}>
                        {this.state.mFilesArray.map((x) => (
                          <div
                            style={{
                              padding: '2px',
                              marginBottom: '1px',
                              marginTop: '2px',
                              width: '50%',
                            }}

                            className='flex-between mFiles-hover flex-align'>
                            <div className='flex-align'>
                              <AiOutlinePaperClip />
                              <a href={x.docPath} style={{ marginLeft: '3px', marginBottom: '0px' }}>
                                {x.docTitle}
                              </a>
                              <div
                                className='pointer'
                                style={{ marginLeft: '6px', marginBottom: '0px', display: 'inline' }}
                                onClick={() => {
                                  this.del(x.key);
                                }}>
                                <MdDelete style={{ fontSize: '16px' }} />
                              </div>

                            </div>

                          </div>
                        ))}
                      </div>


                    </div>

                    <TrainingRequestDetails
                      // height={height}
                      id={this.props.requestId}
                      beneficiary={this.props.beneficiary}
                      category={this.props.category}
                      firstName={this.props.firstName}
                      lastName={this.props.lastName}
                      name={this.props.name}
                      title={this.props.title}
                      approvalStatus={this.props.approvalStatus}
                      cost={this.props.cost}
                      status={this.props.status}
                      details={this.props.details}
                      date={this.props.date}
                      documents={this.props.documents}
                      approverDocuments={this.props.approverDocuments}
                      style={{ transition: 'all 0.6s ease-in' }}

                    />
                  </div>
                </div>

                {this.state.status === null && <div className='w-100 m-t-10 flex flex-v-center flex-h-center space-between'>
                  <button className='btn btn-large w-49' type='submit' onClick={this.submitForm}>
                    {this.state.approveButton}
                  </button>
                  <button
                    className='cancel-btn btn-large w-49'
                    type='button'
                    onClick={this.submitForm2}>
                    {this.state.DeclineButton}
                  </button>
                </div>}
              </div>

              {/* <button
                type='button'
                className='btn w-100 btn-large flex flex-v-center space-between m-b-5'
                onClick={this.uploadProfilePix}>
                <BsUpload style={{ color: '#fff' }} />
                Upload Certificate
                <input type='file' hidden id='image-upload' />
              </button>
              <img className='m-b-10 w-100' src={certUrl || ''} alt='upload certificate...' /> */}
            </div>

          </div>

        </div>
        {loading ? <LoadingSpinner /> : null}
      </>
    );
  }
}

export default ManageRequestFormModal;
