/* eslint-disable linebreak-style */
export const URLAPI = {
  Auth: {
    Post: '/api/Auth/signin',
    PostId: '/api/Auth/signin',
  },
  requestCategories: {
    Post: '/api/RequestCategories',
    Get: '/api/RequestCategories',
    Put: '/api/RequestCategories',
    Delete: '/api/RequestCategories',
  },

  requestApprovalSetups: {
    Post: '/api/RequestApprovalSetups',
    Get: '/api/RequestApprovalSetups',
    Delete: '/api/RequestApprovalSetups',
    Put: '/api/RequestApprovalSetups',
  },

  requestDetails: {
    Post: '/api/RequestDetails/SaveRequestDetails',
    Get: '/api/RequestDetails/GetRequestDetails',
    Put: '/api/RequestDetails/UpdateRequestDetails',
    Delete: '/api/RequestDetails/DeleteRequestDetails',
  },

  requestApprovals: {
    Get: '/api/RequestApprovals',
    Get2: '/api/RequestApprovals/TreatedRequests',
    Put: '/api/RequestApprovals',
  },

  submitRequestDetails: '/api/RequestDetails/Submit_TableRequestDetails',
  saveSubmitRequestDetails: '/api/RequestDetails/Save_SubmitRequestDetails',

  employee: '/api/Employees',

  dashboard: '/api/DashBoard/TransactionHistory',
  requestCount: '/api/DashBoard/RequestCount',

  reportDataPost: '/api/Report/GetReportData',
  beneficiary: '/api/Report/GetBeneficiary',
  reportCount: '/api/Report/ReportCount',
  reportStatus: '/api/Report/GetTransactionStatus',
  requester: '/api/Report/Get_Requester',
  approvers: '/api/Report/Get_Approver',
  reportApprovalRecord: '/api/Report/ReportApprovalRecord',
};
