/* eslint-disable no-console */
/* eslint-disable react/no-deprecated */
// import { Document, Page } from 'react-pdf';
// eslint-disable-next-line no-unused-vars
import React, { Component, PropTypes } from 'react';
import logo from '../../assets/images/edsg-logo-250.png';
import { PDFExport } from "@progress/kendo-react-pdf";
import parser from 'html-react-parser';
// import { Get } from '../../utility/fetch';
import { IoMdClose } from 'react-icons/io';
import LoadingSpinner from '../../resources/loadingSpinner'
import { Get, GetwithQueryString, GetwithQueryStringDoc } from '../../utility/fetch';
// import { URLAPI } from '../../utility/ApiMethods';
// download html2canvas and jsPDF and save the files in app/ext, or somewhere else
// the built versions are directly consumable
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { get } from '../../api-services/fetch';

const tableBorder = {
  border: '1px solid black',
  borderRight: '1px solid black',
  borderBottom: '1px solid black',
};

let Title = '';

export default class MemoPDF extends Component {
  constructor(props) {
    super(props);
    this.pdfExportComponent = React.createRef(null);
    this.state = {
      category: '',
      title: '',
      // beneficiary: '',
      cost: '',
      src: [],
      documents: [],
      details: '',
      fullname: '',
      requestid: 0,
      approvalRecord: [],
      approverDocuments: [],
      show: false,
      mda: '',
      requestor: '',
      signature64: '',
    };
  }

  componentDidMount() {
console.log(typeof this.props.details)
    Title = this.props.title;
    console.log(this.props);
    this.setState({
      requestid: this.props.id,
      category: this.props.category,
      title: this.props.title,
      // beneficiary: this.props.beneficiary,
      cost: this.props.cost,
      details: this.props.details,
      fullname: this.props.firstName + ' ' + this.props.lastName,
      date: this.props.date,
      documents: this.props.document,
      approverDocuments: this.props.approverDocuments,
      mda: this.props.mda,
      requestor: this.props.requestor,
      signature64: this.props.signature64
    });
  }

  returnBase64(data) {
    console.log(data)
    GetwithQueryStringDoc('/services/api/documents/Getdoc', { name: "url", value: data }, this.afterGetBase64)
  }

  afterGetBase64 = (data) => {
    // console.log(data)
    data &&
      this.setState({ src: [...this.state.src, data] })
  }

  printDocument() {

    console.log('clicked');
    const input = document.getElementById('divToPrint');

    html2canvas(input, { allowTaint: true, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = new jsPDF('p', 'px', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;
      pdf.addImage(imgData, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
      // pdf.output('dataurlnewwindow');
      pdf.save(`${Title} Memo.pdf`);
    });
  }

  render() {
    return (
      <>
        <div
          className='overlay'
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 2px 6px #0000000A',
          }}>
          <div className='mb5 flex justify-content-center m-b-20'>
            <button style={{ width: '650px' }} className='btn btn ' onClick={() => {
              if (this.pdfExportComponent.current) {
                this.pdfExportComponent.current.save();
              }
            }}>
              Download
            </button>
          </div>
          <div className='flex flex-v-center flex-h-center flex-direction-column'>
            <div
              className='modal-box'
              style={{
                boxShadow: '0px 2px 6px #0000000A',
                position: 'relative',
                width: '650px',
              }}>
              <IoMdClose
                className='pointer m-l-5'
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '125%',
                  fontSize: '24px',
                  color: '#fff',
                  width: '40px',
                  height: '40px',
                  backgroundColor: '#F74F11',
                  padding: '3px',
                }}
                onClick={this.props.closeModal}
              />

              <div className='content no-padding '>
                <PDFExport paperSize="A3" margin="8mm" fileName={`${Title} Memo.pdf`} ref={this.pdfExportComponent}>
                  <div
                    id='divToPrint'
                    style={{
                      backgroundColor: 'white',
                      width: '210mm',
                      minHeight: '297mm',
                      marginLeft: 'auto',
                      marginRight: 'auto',


                    }}>
                    <div className='m-t-30'>
                      <div className='flex justify-content-center m-t-30'>
                        <img className=' m-t-40' src={logo} alt='Edo state logo' />
                      </div>
                      <p style={{ textAlign: 'center', margin: '10px 10px' }}>
                        Edo State Government Secretariat Oka, Benin City, Edo State
                      </p>
                    </div>
                    <div className='flex justify-content-center m-t-10 '>
                      <h1>{this.props.mda}</h1>
                    </div>
                    <hr className='m-b-20 mx-40' />

                    <div className='flex justify-content-center m-t-7 '>
                      <h2> EDSG General Request</h2>
                    </div>

                    <div className='flex justify-content-center m-t-10 '>
                      <h2>{this.props.name}</h2>
                    </div>

                    <div className='flex space-between mx-40'>
                      <p><b>ID:</b></p> <p>{this.props.id}</p>
                    </div>
                    <hr className='mx-45' />
                    <div className='flex space-between m-t-20 mx-40'>
                      <p><b>Category:</b></p> <p>{this.props.category}</p>
                    </div>
                    <hr className='mx-45' />
                    <div className='flex space-between m-t-20 mx-40'>
                      <p><b>Title:</b></p> <p>{this.props.title}</p>
                    </div>
                    <hr className='mx-45' />
                    <div className='flex space-between m-t-20 mx-40'>
                      <p><b>Request Date:</b></p> <p>{this.props.date || this.state.createdAt}</p>
                    </div>
                    <b></b>
                    <hr className='mx-45' />
                    <div className='flex space-between m-t-20 mx-40'>
                      <p><b>Cost Implication:</b></p> <p> ₦ {this.props.cost}</p>
                    </div>
                    <hr className='mx-45' />
                    <div className='flex space-between m-t-20 mx-40'>
                      <p><b>Requester Name:</b></p> <p>{this.props.requestor || this.props.initiator}</p>
                    </div>
                    <hr className='mx-45' />
                    <div className=' m-t-20 mx-40'>
                      <p><b>Request Details:</b></p> <div>{parser(this.props?.details?.replaceAll("<td>", "<td className='border-table'>"))}</div>
                    </div>
                    <hr className='mx-45' />
                    <div className=' m-t-20 mx-40'>
                      <p><b>Attached Documents:</b></p>
                      <div>
                        {Array.isArray(this.props?.documents) && Array.isArray( this.props?.approverDocuments) && [...this.props?.document, ...this?.props?.approverDocuments]?.map((item) => (
                          <div
                            style={{ display: 'flex', alignItems: 'center', color: 'blue'}}
                            className='d-flex m-b-10 '>
                            <p className='flex' >
                              {/* <img src={doc} alt='doc' style={{ width: '20px' }} /> */}
                              <p>{item.docTitle}</p>
                            </p>

                          </div>
                        ))}
                      </div>
                    </div>
                    <hr className='mx-45' />



                    <div className='m-t-20 mx-40'>
                      <div
                        id='bordered-table'
                        className='table-view '
                        style={{
                          borderCollapse: 'collapse',
                          borderSpacing: '5px 15px',
                          // border: 'none',
                          ...this.props.overrideTableStyles,
                        }}>
                        <div
                          className='table-header'
                          style={{
                            backgroundColor: `${this.props.headerBGColor || '#d5ffd5'}`,
                            color: `${this.props.headerColor || '#43425D'}`,
                          }}>
                          <div className='table-row'>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Approver</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Date</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Status</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Comment</p>
                            </div>
                            <div className='th case title w-25' style={{ ...tableBorder }}>
                              <p>Signature</p>
                            </div>
                          </div>
                        </div>
                        {this.props.record.length === 0 ? (
                          <h4 className='m-t-10'>No approval yet for this request</h4>
                        ) : (
                          <div className='table-body no-border'>
                            {this.props.record.map((item, idx) => (
                              <div className='table-row no-border' key={idx}>

                                <div
                                  className='td font-sz-13'
                                  style={{
                                    ...tableBorder,
                                  }}>
                                  {item.approver}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.date}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.status}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>
                                  {item.comment}
                                </div>
                                <div className='td font-sz-13' style={{ ...tableBorder }}>

                                  {item.signature64 ? (
                                    <img
                                      width='100%'
                                      height='70px'
                                      src={`data:image/jpeg;base64, ${item.signature64}`}
                                      alt='signature'
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </PDFExport>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
