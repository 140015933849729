/* eslint-disable */
import Cookies from 'js-cookie';

export const logout = () => {
  Cookies.remove('_AppToken');
  Cookies.remove('tokenExist');
  Cookies.remove('token');
  Cookies.remove('requestId');
  window.location.assign(Cookies.get('_HomeLink'));
};

export default logout;