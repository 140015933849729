/* eslint-disable */
import { Component } from 'react';
import { IoMdClose } from 'react-icons/io';
import * as React from 'react';
//import moment from 'moment';
import Accordion from '../layouts/Accordion';
// icons
import { BsCaretDownFill } from 'react-icons/bs';
import parser from 'html-react-parser';
import CommentModal from '../layouts/CommentModal';
// import Successmodal from '../layouts/SuccessModal';
import ErrorModal from '../layouts/ErrorModal';
// Api
import { withRouter } from 'react-router-dom';
import { Get } from '../../utility/fetch';
import { URLAPI } from '../../utility/ApiMethods';
// import { Put, Get } from '../../utility/fetch';
// Image
import imageSrc from '../../assets/images/user-avater.png';
import { formatFileUrl, formatReviewStatus } from '../../utility/general';

import ManageRequestFormModal from './ManageRequestFormModal';
import MemoPDF from '../inputs/MemoPDF';
import doc from '../../assets/images/doc.png';
import PictureModal from './PictureModal';
//import moment from 'moment';

const tableBorder = {
  border: 'none',
  borderRight: '1px solid #0000000A',
  borderBottom: '1px solid #0000000A',
};

class TrainingRequestDetails extends Component {
  constructor(props) {
    super(props);
    console.log(props, 'propssssssss');
    this.state = {
      category: '',
      showPicture: false,
      picture: '',
      title: '',
      beneficiary: '',
      cost: '',
      documents: [],
      details: '',
      
      fullname: '',
      signature64: '',
      requestid: 0,
      approvalRecord: [],
      approverDocuments: [],
      show: false,
      showsDocument: false,
      mda: '',
    };
    console.log(this.state, 'loloookk');
  }

  getReportApprovalRecord = (data) => {
    console.log(data)
    let _approvalRecord = Array.isArray(data) ? data : [];

    this.setState({ approvalRecord: _approvalRecord, loading: false });
  };

  showDocument = () => {
    this.setState({
      show: true,
      showsDocument: true,
    });
  };

  showPictureModal = (pics) => {
    this.setState({ showPicture: true })
    this.setState({ picture: pics })
  }

  closePictureModal = () => {
    this.setState({ showPicture: false })

  }

  closeModal = () => {
    this.setState({ show: false });
    this.setState({ showsDocument: false });
  };

  componentDidMount() {
    Get(URLAPI.reportApprovalRecord + '/' + this.props.id, this.getReportApprovalRecord);
    console.log(this.props, 'propaaa');
    this.setState({
      requestid: this.props.id,
      category: this.props.category,
      title: this.props.title,
      beneficiary: this.props.beneficiary,
      cost: this.props.cost,
      details: this.props.details,
      fullname: this.props.firstName + ' ' + this.props.lastName,
      documents: this.props.document,
      approverDocuments: this.props.approverDocuments,
      mda: this.props.mda,
    });

    console.log(this.state, 'koooooooo');
  }

  render() {
    const { match, location, history } = this.props;

    if (location.pathname === '/admin-portal/request/manage-request') {
      return (
        <>
         {
                        [...this.props?.documents, ...this.props?.approverDocuments].map((item) => (
                          (item.docPath?.includes('.jpg') || item.docPath?.includes('.png') || item.docPath?.includes('.gif'))
                            ?
                            <div
                              style={{ display: 'flex', alignItems: 'center', color:'blue', textDecoration:'underline' }}
                              onClick={()=>this.showPictureModal(item.docPath)}
                              className='d-flex m-b-10 '>
                              <a className='flex' >
                                <img src={doc} alt='doc' style={{ width: '20px' }} />
                                <p className='pointer'>{item.docTitle}</p>
                              </a>
                              
                            </div>
                            :
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              className='d-flex '>
                              <a className='flex m-b-10' target='_blank' href={`${item.docPath}`}>
                                <img src={doc} alt='doc' style={{ width: '20px' }} />
                                <p className='pointer'>{item.docTitle}</p>
                              </a>
                              
                            </div>
                        ))

                      }

          {/* {this.props.approverDocuments.map((item, i) => (
            <div style={{ margin: '5px' }}>
              {' '}
              <a key={i} href={item.docPath}>
                {item.docTitle}
              </a>{' '}
            </div>
          ))} */}

          <div className='m-t-20'>
            <div
              id='bordered-table'
              className='table-view '
              style={{
                borderCollapse: 'collapse',
                borderSpacing: '5px 15px',
                ...this.props.overrideTableStyles,
              }}>
              <div
                className='table-header'
                style={{
                  backgroundColor: `${this.props.headerBGColor || '#d5ffd5'}`,
                  color: `${this.props.headerColor || '#43425D'}`,
                }}>
                <div className='table-row'>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Approver</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Date</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Status</p>
                  </div>
                  <div className='th case title w-25' style={{ ...tableBorder }}>
                    <p>Comment</p>
                  </div>
                </div>
              </div>
              {this.state.approvalRecord.length === 0 ? (
                <h4 className='m-t-10'>No approval yet for this request</h4>
              ) : (
                <div className='table-body no-border'>
                  {this.state.approvalRecord.map((item, idx) => (
                    <div className='table-row no-border' key={idx}>
                      <div
                        className='td font-sz-13'
                        style={{
                          ...tableBorder,
                        }}>
                        {item.approver}
                      </div>
                      <div className='td font-sz-13' style={{ ...tableBorder }}>
                        {item.date}
                      </div>
                      <div className='td font-sz-13' style={{ ...tableBorder }}>
                        {item.status}
                      </div>
                      <div className='td font-sz-13' style={{ ...tableBorder }}>
                        {item.comment}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className='overlay'
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: '0px 2px 6px #0000000A',
            }}>
            <div className='flex flex-v-center flex-h-center flex-direction-column'>
              <div
                className='modal-box'
                style={{
                  boxShadow: '0px 2px 6px #0000000A',
                  position: 'relative',
                  width: this.state.showsDocument ? '0px' : '650px',
                }}>
                <div className='content no-padding '>
                  <IoMdClose
                    className='pointer m-l-5'
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '100%',
                      fontSize: '24px',
                      color: '#fff',
                      width: '40px',
                      height: '40px',
                      backgroundColor: '#F74F11',
                      padding: '3px',
                    }}
                    onClick={this.props.closeModal}
                  />
                  <div className='w-100 bg-white pd-10'>
                    <div className='flex flex-direction-column'>

                      <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                        <div className='col-2-4 no-padding no-margin bold-text'>Category: </div>
                        <div className='w-80 bold-text'>{this.state.category} </div>
                      </div>
                      <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                        <div className='col-2-4 no-padding no-margin bold-text'>Title: </div>
                        <div className='w-80 bold-text'>{this.state.title}</div>
                      </div>
                      <div className='flex m-t-10 border-bottom-gray-1 pd-b-5'>
                        <div className='col-2-4 no-padding no-margin bold-text'>
                          Cost Implication:{' '}
                        </div>
                        <div className='w-80 bold-text'>₦{this.state.cost} </div>
                      </div>

                      <div className='flex m-t-10 border-bottom-gray-1 pd-b-5 m-b-10'>
                        <div className='col-2-4 no-padding no-margin bold-text'>
                          Request Details :{' '}
                        </div>
                        <div className='w-80 bold-text'>{parser(this.state.details.replaceAll("<strong>", "<strong className='bolder-text'>")?.replaceAll("<td>", "<td className='border-table'>"))} </div>
                      </div>
                      {
                        this.props.name !== 'Approved' && this.props.name !== 'Declined' &&
                        <div className='flex m-t-10 border-bottom-gray-1 pd-b-5 m-b-10'>
                        <div className='col-2-4 no-padding no-margin bold-text'>
                          Currently With:{' '}
                        </div>
                        <div className='w-80 bold-text'>{this.state.fullname} </div>
                      </div>
                      }
                      

                      {
                        [...this.state.documents, ...this.state.approverDocuments].map((item) => (
                          (item.docPath?.includes('.jpg') || item.docPath?.includes('.png') || item.docPath?.includes('.gif'))
                            ?
                            <div
                              style={{ display: 'flex', alignItems: 'center', color:'blue', textDecoration:'underline' }}
                              onClick={()=>this.showPictureModal(item.docPath)}
                              className='d-flex m-b-10 '>
                              <a className='flex' >
                                <img src={doc} alt='doc' style={{ width: '20px' }} />
                                <p className='pointer'>{item.docTitle}</p>
                              </a>
                              
                            </div>
                            :
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                              className='d-flex '>
                              <a className='flex m-b-10' target='_blank' href={`${item.docPath}`}>
                                <img src={doc} alt='doc' style={{ width: '20px' }} />
                                <p className='pointer'>{item.docTitle}</p>
                              </a>
                              
                            </div>
                        ))

                      }
                      <div className='m-t-20'>
                        <div
                          id='bordered-table'
                          className='table-view '
                          style={{
                            borderCollapse: 'collapse',
                            borderSpacing: '5px 15px',
                            // border: 'none',
                            ...this.props.overrideTableStyles,
                          }}>
                          <div
                            className='table-header'
                            style={{
                              backgroundColor: `${this.props.headerBGColor || '#d5ffd5'}`,
                              color: `${this.props.headerColor || '#43425D'}`,
                            }}>
                            <div className='table-row'>
                              <div className='th case title w-25' style={{ ...tableBorder }}>
                                <p>Approver</p>
                              </div>
                              <div className='th case title w-25' style={{ ...tableBorder }}>
                                <p>Date</p>
                              </div>
                              <div className='th case title w-25' style={{ ...tableBorder }}>
                                <p>Status</p>
                              </div>
                              <div className='th case title w-25' style={{ ...tableBorder }}>
                                <p>Comment</p>
                              </div>

                            </div>
                          </div>
                          {this.state.approvalRecord.length === 0 ? (
                            <h4 className='m-t-10'>No approval yet for this request</h4>
                          ) : (
                            <div className='table-body no-border'>
                              {this.state.approvalRecord.map((item, idx) => (
                                <div className='table-row no-border' key={idx}>
                                  <div
                                    className='td font-sz-13'
                                    style={{
                                      ...tableBorder,
                                    }}>
                                    {item.approver}
                                  </div>
                                  <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item.date}
                                  </div>
                                  <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item.status}
                                  </div>
                                  <div className='td font-sz-13' style={{ ...tableBorder }}>
                                    {item.comment}
                                  </div>

                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {

                this.state.showPicture && <PictureModal closeModal={this.closePictureModal} picture={this.state.picture} />

              }

              {this.state.show && (
                <MemoPDF
                  className='m-t-30'
                  id={this.props.id}
                  beneficiary={this.props.beneficiary}
                  category={this.props.category}
                  firstName={this.props.firstName}
                  lastName={this.props.lastName}
                  name={this.props.name}
                  title={this.props.title}
                  cost={this.props.cost}
                  document={this.props.document}
                  approverDocuments={this.props.approverDocuments}
                  date={this.props.requestDate}
                  closeModal={this.closeModal}
                  style={{ transition: 'all 0.6s ease-in' }}
                  record={this.state.approvalRecord}
                  details={this.props.details}
                  mda={this.state.mda}
                  requestor={this.props.requestor}
                  signature64={this.props.signature64}
                />
              )}
            </div>
            {this.props.name === 'Approved' && (
              <div className='mb5 flex justify-content-center m-t-20'>
                <button style={{ width: '650px' }} className='btn btn ' onClick={this.showDocument}>
                  View Document
                </button>
              </div>
            )}
          </div>

        </>
      );
    }
  }
}

export default withRouter(TrainingRequestDetails);
